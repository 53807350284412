<template>
	<div class="createParks">
		<div class="main-filter">
			<el-form inline :model="queryData" class="demo-form-inline search-form">
				<el-form-item label="名称：">
					<el-autocomplete v-model.trim="queryData.name" :fetch-suggestions="querySearchAsync" clearable placeholder="请输入内容"></el-autocomplete>
				</el-form-item>
				<el-form-item label="起止时间：">
					<el-date-picker
						v-model="time"
						value-format="yyyy-MM-dd HH:mm:ss"
						type="daterange"
						range-separator="-"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="乡镇/街道：">
					<el-select v-model="queryData.vil" clearable @change="changeVillage" placeholder="请选择">
						<el-option v-for="(item, index) in villageList" :key="index" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="村：">
					<el-select v-model="queryData.are" clearable placeholder="请选择">
						<el-option v-for="(item, index) in areaList" :key="index" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="驻派状态：">
					<el-select v-model="queryData.state" clearable placeholder="请选择">
						<el-option v-for="(item, index) in stateOption" :key="index" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
				<el-button type="primary" @click="modifyParkInfo($event, false, 'new')" icon="el-icon-plus">新增</el-button>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:operation-column-width="140"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<!-- 插槽操作栏固定属性operation -->
				<template #enabled="{ scope }">
					<el-switch inactive-text="否" active-text="是" v-model="scope.row.enabled" @change="changeOpen(scope.row)"> </el-switch>
				</template>
				<template #seeMore="{ scope }">
					<el-button class="seeMore" type="text" size="mini" @click="seeMore(scope.row)">查看</el-button>
				</template>
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo($event, true, scope.row)" size="mini" type="primary"> 查看 </el-button>
					<el-button @click="modifyParkInfo($event, false, scope.row)" size="mini" type="warning" v-if="!scope.row.enabled"> 修改 </el-button>
					<el-button size="mini" v-else type="danger" @click="toDelete(scope.row)"> 作废 </el-button>
				</template>
			</Table>
		</div>
		<createView ref="createView" @getList="getList" :isShowDetail.sync="isShowDetail" />
		<show ref="show" :checkRow="checkRow"></show>
	</div>
</template>

<script>
export default {
	name: 'countyMedicalManager',
	components: {
		Table: () => import('@/components/Table/table'),
		rsForm: () => import('@/components/RsForm/index.vue'),
		createView: () => import('./newDialog.vue'),
		show: () => import('./show.vue'),
	},
	data() {
		return {
			loading: false,
			queryData: {
				current: 1,
				size: 10,
				discriminationCode: 'D',
				state: null,
				name: null,
			},
			typeDictionaryItemList: [],
			sexDictionaryItemList: [],
			total: 0,
			stateOption: [
				{ label: '全部', value: '' },
				{ label: '未开始', value: 'U' },
				{ label: '进行中', value: 'P' },
				{ label: '已结束', value: 'F' },
			],
			tableData: [],
			tableHead: [
				{
					label: '驻派计划名称',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '驻派组名称',
					formatter: (row) => {
						return row.medicalWorkerGroup.title || '-';
					},
				},
				{
					label: '驻派开始时间',
					formatter: (row) => {
						return row.startTime || '-';
					},
				},
				{
					label: '驻派结束时间',
					formatter: (row) => {
						return row.endTime || '-';
					},
				},
				{
					label: '驻派范围',
					prop: 'name',
					formatter: this.formatterScan,
				},

				{
					label: '资源配置',
					prop: 'seeMore',
				},
				{
					label: '驻派组状态',
					prop: 'name',
					formatter: (row) => {
						return row.state == 'U' ? '未开始' : row.state == 'P' ? '进行中' : '已结束' || '-';
					},
				},
				{
					label: '是否启用',
					prop: 'enabled',
					width: 116,
				},
			],
			isShowDetail: false,
			villageList: [],
			areaList: [],
			checkRow: {},
			link: '',
			time: [],
		};
	},
	props: [],
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getList();
		this.getVillageList();
	},
	methods: {
		// 作废
		toDelete(row) {
			this.$confirm('此操作将作废该驻派计划, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.$http
						.delete(row._links.patch.href)
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(res.data.message);
								this.getList();
							}
						})
						.catch((e) => {});
				})
				.catch((e) => {});
		},
		seeMore(item) {
			this.checkRow = item;
			this.$refs.show.showVisible = true;
		},
		// 格式化
		formatterScan(item) {
			let str = '';
			item.resourceAllocations.forEach((itr) => {
				let str1 = '';
				itr.regions.forEach((itr1) => {
					str1 += itr1?.title || '';
				});
				str += str1 + ';';
			});
			return str;
		},
		changeVillage(e) {
			this.getAreaList(e);
		},
		// 获取村列表
		getVillageList(code) {
			let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));

			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code: userInfo.chinaRegionDtoList[2].code } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.villageList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取村列表
		getAreaList(code) {
			if (code) {
				this.$http
					.get(this.api['ChinaRegions#index'].href, { params: { code: code } })
					.then((res) => {
						if (res.data && res.data.success) {
							this.areaList = res.data.collection || [];
						}
					})
					.catch((e) => {});
			} else {
				this.areaList = [];
				this.queryData.are = '';
			}
		},
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api['ChinaRegions#index'].href)
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
						this.economizeList.unshift({
							title: '全部',
							code: '',
						});
					}
				})
				.catch((e) => {});
		},
		// 切换省份后拿到市区数据
		changeEcon(val) {
			this.queryData.cityCode = '';
			if (!val || val === '') return (this.marketList = []);
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code: val } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.marketList = res.data.collection || [];
						this.marketList.unshift({
							title: '全部',
							code: '',
						});
					}
				})
				.catch((e) => {});
		},
		//切换市区  获取县级数据
		changeMarket(val) {
			console.log(val, 'val');
		},
		onSearch() {
			this.queryData.current = 1;
			if (this.queryData.vil && !this.queryData.are) {
				this.queryData.villageCode = this.queryData.vil;
			} else if (this.queryData.are) {
				this.queryData.villageCode = this.queryData.are;
			} else {
				this.queryData.villageCode = '';
			}

			this.getList('');
		},
		changeOpen(row) {
			if (!row.enabled) {
				this.$message.warning('已启用计划，无法进行修改');
				row.enabled = true;
				return;
			}
			this.$http
				.patch(row._links.patch.href, { enabled: row.enabled })
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success('操作成功');
						this.getList();
					}
				})
				.catch((e) => {});
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? '' : (this.loading = true);
			let data = {
				...this.queryData,
				startTime: this.time && this.time.length > 0 ? this.time[0] : null,
				endTime: this.time && this.time.length > 0 ? this.time[1] : null,
			};
			this.$http
				.get(this.api['TourPlans#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.filterLoading = false;
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.name,
								};
							});
						} else {
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
							this.link = res.data._links.create.href || '';
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(event, isShowDetail, row) {
			this.$set(this, 'isShowDetail', isShowDetail);
			this.$refs.createView.init(row, this.link);
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .search-form {
	.el-select {
		width: 120px;
		.el-input {
			width: 120px;
			.el-input__inner {
				width: 120px;
			}
		}
	}
	.el-input {
		width: 160px;
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
.seeMore {
	color: #1db9b1;
}
</style>
